import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Popper, Typography, Paper, Fade } from "@material-ui/core";


import {
    toggleSidebar,
    hideRightSidebar,
    changeTopbarTheme,
    changeLayout,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader,
} from "../../../store/actions";

// MetisMenu
import MetisMenu from "metismenujs";
import MuiPopper from "../../../pages/ReusableComp/MuiPopper/MuiPopper";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_toggle: false,
            layoutType: this.props.layoutType,
            topbarTheme: this.props.topbarTheme,
            leftSideBarTheme: this.props.leftSideBarTheme,
            layoutWidth: this.props.layoutWidth,
            sidebarType: this.props.leftSideBarType,
            isPreloader: this.props.isPreloader,
            popperStatus: false,
            popperAnchor: null,
        };
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
        this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
        this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
        this.changeLeftSidebarType = this.changeLeftSidebarType.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
    }

    componentDidMount() {
        document.body.setAttribute("data-sidebar", "dark");
        this.initMenu();
    }

    //update local state after changing layout
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                topbarTheme: this.props.topbarTheme,
                leftSideBarTheme: this.props.leftSideBarTheme,
                layoutWidth: this.props.layoutWidth,
                sidebarType: this.props.leftSideBarType,
                isPreloader: this.props.isPreloader,
            });
        }

        if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add("mm-active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active"); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };
    //change layput type and dispatch action
    changeLayout() {
        this.props.changeLayout("horizontal");
    }

    //theme preloader
    changeThemePreloader = (value) => {
        this.props.changePreloader(!this.props.isPreloader);
    };

    //change left sidebar theme
    changeLeftSidebarTheme(value) {
        this.props.changeSidebarTheme(value);
    }

    //change layout width
    changeLayoutWidth(value) {
        if (this.state.layoutWidth === "boxed")
            this.props.changeLayoutWidth("fluid", this.state.layoutType);
        else this.props.changeLayoutWidth("boxed", this.state.layoutType);
    }

    //change topbar theme and dispatch action
    changeTopbarTheme(value) {
        this.props.changeTopbarTheme(value);
    }

    //change sidebar type
    changeLeftSidebarType(value) {
        this.props.changeSidebarType(value);
    }

    sidebarTabClicked = (e, type) => {
        switch (type) {
            case "client-tab":
                this.setState({ popperStatus: !this.state.popperStatus });
                this.setState({ popperAnchor: e.currentTarget });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Main</li>

                        {/* <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="mdi mdi-view-dashboard"></i>
                                <span className="badge badge-pill badge-primary float-right">
                                    2
                                </span>
                                <span>Dashboard</span>
                            </Link>
                        </li> */}

                        <li>
                            <Link to="staffing_sheet" className=" waves-effect">
                                <i className="mdi mdi-calendar-check"></i>
                                <span>Staffing Sheets</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/order" className=" waves-effect">
                                <i className="mdi mdi-border-color"></i>
                                <span>Order</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-office-building"></i>
                                <span>Client</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/client_setup">Setup</Link>
                                </li>
                                <li>
                                    <Link to="/billing_rates">Billing Rates Setup</Link>
                                </li>
                                <li>
                                    <Link to="/pay_rates">Pay Rates Setup</Link>
                                </li>
                                <li>
                                    <Link to="/staffing_sheet">Staffing Sheets</Link>
                                </li>
                                <li>
                                    <Link to="/client_cred">Client Credentials</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-human-child"></i>
                                <span>Contractor</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/contractor_setup">Setup</Link>
                                </li>
                                <li>
                                    <Link to="/contractor_schedule">Schedule</Link>
                                </li>
                                <li>
                                    <Link to="/contractor_availability">Availability</Link>
                                </li>
                                <li>
                                    <Link to="/contractor_pay_rates">Pay Rates Setup</Link>
                                </li>
                                <li>
                                    <Link to="/contractor_dnr">DNR</Link>
                                </li>
                                <li>
                                    <Link to="/new_contractor">New Contractor</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-coin-outline"></i>
                                <span>Payroll</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/new_payroll">New</Link>
                                </li>
                                <li>
                                    <Link to="/view_all_payroll">View & Edit</Link>
                                </li>
                                {/* <li>
                                    <Link to="/direct_payroll">Management</Link>
                                </li> */}
                            </ul>
                        </li>

                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-newspaper-variant"></i>
                                <span>Invoice</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/new_invoice">New</Link>
                                </li>
                                <li>
                                    <Link to="/view_all_invoice">View & Edit</Link>
                                </li>
                                {/* <li>
                                    <Link to="/direct_invoice">Management</Link>
                                </li> */}
                            </ul>
                        </li>

                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-message-text-clock"></i>
                                <span>Reports</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/work_summary_report">Work Summary</Link>
                                </li>
                                <li>
                                    <Link to="/shift_status_report">Shift Status</Link>
                                </li>
                                {/* <li>
                                    <Link to="/contractor_listing_report">Contractor Listing</Link>
                                </li> */}
                                <li>
                                    <Link to="/client_listing_report">Client Listing</Link>
                                </li>
                                <li>
                                    <Link to="/invoice_register_report">Invoice Register</Link>
                                </li>
                                <li>
                                    <Link to="/cheque_register_report">Cheque Register</Link>
                                </li>
                                <li>
                                    <Link to="/report_summary">Report Summary</Link>
                                </li>
                                {/* <li>
                                    <Link to="/order_report">Order Report</Link>
                                </li>
                                <li>
                                    <Link to="/pay_rate_report">Pay Rate report</Link>
                                </li>
                                <li>
                                    <Link to="/bill_rate_report">Bill Rate report</Link>
                                </li>
                                <li>
                                    <Link to="/payroll_report">Payroll Report</Link>
                                </li>
                                <li>
                                    <Link to="/invoice_report">Invoice Report</Link>
                                </li> */}

                            </ul>
                        </li>

                        <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-settings"></i>
                                <span>System</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/request_of_change">Request of Change</Link>
                                </li>
                                <li>
                                    <Link to="/setting_notifications">Notifications</Link>
                                </li>
                                <li>
                                    <Link to="/manage_lists">Manage Lists</Link>
                                </li>
                                <li>
                                    <Link to="/general_shifts">General Shifts</Link>
                                </li>
                                {/* <li>
                                    <Link to="/manage_status">Manage Status</Link>
                                </li> */}
                                <li>
                                    <Link to="/manage_credentials">Manage Credentials</Link>
                                </li>
                                <li>
                                    <Link to="/setting/pay_rates">Pay Rates</Link>
                                </li>
                                <li>
                                    <Link to="/setting/bill_rates">Bill Rates</Link>
                                </li>
                                <li>
                                    <Link to="/setting/holiday">Holiday</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li>
                            <Link to="client" className="has-arrow waves-effect">
                                <i className="mdi mdi-settings"></i>
                                <span>Timeslip Mgmt.</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li>
                                    <Link to="/direct_payroll">Received</Link>
                                </li>
                                <li>
                                    <Link to="/direct_invoice">Processed</Link>
                                </li>
                                <li>
                                    <Link to="/trash_payroll">Trash</Link>
                                </li>
                            </ul>
                        </li> */}
                        <li>
                            <Link to="/notifications" className=" waves-effect">
                                <i className="ti-bell font-size-23"></i>
                                <span>Notifications</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <MuiPopper
                    isOpen={this.state.popperStatus}
                    anchorEl={this.state.popperAnchor}
                />

                <Popper
                    open={this.state.popperStatus}
                    anchorEl={this.state.popperAnchor}
                    style={{ backgroundColor: "red" }}
                >
                    <Paper>
                        <Typography>The content of the Popper.</Typography>
                    </Paper>
                </Popper>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const {
        is_toggle,
        leftSideBarType,
        layoutType,
        leftSideBarTheme,
        layoutWidth,
        topbarTheme,
        isPreloader,
    } = state.Layout;
    return {
        is_toggle,
        leftSideBarType,
        layoutType,
        leftSideBarTheme,
        layoutWidth,
        topbarTheme,
        isPreloader,
    };
};

export default withRouter(
    connect(mapStatetoProps, {
        toggleSidebar,
        hideRightSidebar,
        changeLayout,
        changeTopbarTheme,
        changeSidebarTheme,
        changeLayoutWidth,
        changeSidebarType,
        changePreloader,
    })(SidebarContent)
);
