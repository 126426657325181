import React from 'react';
import classNames from 'classnames';
import styles from './PrivacyPolicy.module.css';
import { Link } from 'react-router-dom';
import logodark from "../../assets/images/RN_logo.png";


const PrivacyPolicy = () => {
    return (
        <div className={classNames(styles.container)}>
            <h3 className="text-center mt-4">
                <Link to="\" className="logo logo-admin"><img src={logodark} height="85" alt="logo" /></Link>
            </h3>
            <h1 className={classNames(styles.heading)}>Privacy Policy</h1>

            <h2 className={classNames(styles.subheading)}>1. Introduction</h2>
            <p>RNPlus.net ("we," "our," or "us") is committed to safeguarding the privacy of our users ("you" or "your"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information.</p>

            <h2 className={classNames(styles.subheading)}>2. Information We Collect</h2>
            <ul>
                <li><strong>Personal Identification Information:</strong> Name, email address, phone number, mailing address, etc.</li>
                <li><strong>Professional Information:</strong> Licensure details, certifications, education, and employment history.</li>
                <li><strong>Usage Data:</strong> IP address, browser type, referral URLs, pages viewed, and visit timestamps.</li>
            </ul>

            <h2 className={classNames(styles.subheading)}>3. How We Use Your Information</h2>
            <ul>
                <li><strong>Providing Services:</strong> Matching healthcare professionals with job opportunities.</li>
                <li><strong>Communication:</strong> Responding to inquiries and sending updates.</li>
                <li><strong>Improvement:</strong> Analyzing website usage to enhance services.</li>
                <li><strong>Compliance:</strong> Meeting legal obligations and protecting our interests.</li>
            </ul>

            <h2 className={classNames(styles.subheading)}>4. Information Sharing and Disclosure</h2>
            <ul>
                <li><strong>With Healthcare Facilities:</strong> Sharing professional profiles with consent.</li>
                <li><strong>Service Providers:</strong> Partnering with third-party vendors for operations.</li>
                <li><strong>Legal Requirements:</strong> Complying with law enforcement or legal obligations.</li>
            </ul>

            <h2 className={classNames(styles.subheading)}>5. Data Security</h2>
            <p>We implement security measures to protect your data. However, no method of online transmission is completely secure.</p>

            <h2 className={classNames(styles.subheading)}>6. Your Data Protection Rights</h2>
            <p>Depending on your jurisdiction, you may have rights including:</p>
            <ul>
                <li>Accessing, correcting, or deleting your personal data.</li>
                <li>Restricting or objecting to processing under certain conditions.</li>
                <li>Requesting data portability to another organization.</li>
            </ul>

            <h2 className={classNames(styles.subheading)}>7. Cookies and Tracking Technologies</h2>
            <p>Our website may use cookies to enhance your experience. You can disable cookies in your browser settings.</p>

            <h2 className={classNames(styles.subheading)}>8. Third-Party Links</h2>
            <p>We are not responsible for external websites linked from RNPlus.net. Please review their privacy policies.</p>

            <h2 className={classNames(styles.subheading)}>9. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. Any changes will be posted here with an updated effective date.</p>

            <h2 className={classNames(styles.subheading)}>10. Contact Us</h2>
            <p>If you have any questions, please contact us at:</p>
            <p><strong>RNPlus.net</strong><br />
                515 Pennsylvania Ave Suite 103, Fort Washington, PA 19034<br />
                Email: <a href="mailto:staffing@rnplus.net" className={classNames(styles.link)}>staffing@rnplus.net</a><br />
                Phone: 1-866-2RNPLUS (276-7587)
            </p>
        </div>
    );
};

export default PrivacyPolicy;
