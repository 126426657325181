import React from "react";
import classNames from "classnames";
import { Link } from 'react-router-dom';

import "./styles.css";
import step1 from "../../assets/images/delete/step1.jpeg"
import step2 from "../../assets/images/delete/step2.jpeg"
import step3 from "../../assets/images/delete/step3.jpeg"
import step4 from "../../assets/images/delete/step4.jpeg"
import step5 from "../../assets/images/delete/step5.jpeg"
import step6 from "../../assets/images/delete/step6.jpeg"
import logodark from "../../assets/images/RN_logo.png";


const DeleteContractorProcess = () => {
    const steps = [
        "Login to the contractor app.",
        "Click on the menu button at the top left.",
        "Click on 'Profile' from the menu items.",
        "Scroll down to the bottom of the profile page and click 'Delete Account'.",
        "Click 'Yes' on the final confirmation popup. An OTP will be sent to your registered email ID.",
        "Enter the OTP and click 'Delete Account'. Your account will then be deleted."
    ];

    const images = [
        step6,
        step1,
        step2,
        step3,
        step4,
        step5
    ]
    return (
        <div className={classNames("container_delete")}>
            <h3 className="text-center mt-4">
                <Link to="\" className="logo logo-admin"><img src={logodark} height="85" alt="logo" /></Link>
            </h3>
            <h2 className={classNames("heading_delete")}>How to Delete Your Contractor Account</h2>
            <div className={classNames("steps_delete")}>
                {steps.map((step, index) => (
                    <div key={index} className={classNames("step_delete")}>
                        <p className={classNames("step-text")}>Step {index + 1}: {step}</p>
                        <img
                            src={images[index]}
                            alt={`Step ${index + 1}`}
                            className={classNames("step-image")}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeleteContractorProcess;