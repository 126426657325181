import React from 'react';
import { allType } from "./Variables/cont";
import { stringAbbreviation } from "./stringManupulation";
import { changeTzToNormal, dateUSStandard } from "./dateFunctions";

const dayName = (day) => {
    if (day === "EVENING")
        return "EVE";
    else if (day === "NIGHT")
        return "NGT";
    else return "DAY"

}



export const generateNotificationString = (n, updateRoute) => {
    let string = []
    let notification = { ...n }
    // console.log(n)
    if (notification && Object.keys(notification).length > 0) {
        let _allDate = Object.keys(notification)

        _allDate.sort((b, a) => {
            return new Date(b) - new Date(a);
        })
        _allDate.forEach((d, i) => {
            // console.log(notification[d])
            string.push(<div className="mt-2 notification-date"><strong>{dateUSStandard(d)}</strong></div>)
            let _allClient = Object.keys(notification[d]);
            // console.log(_allClient)

            _allClient.forEach((c, j) => {
                let _allDay = Object.keys(notification[d][c]);
                // console.log(notification[d][c], _allDay)
                string.push(<div className="notification-client">{c}</div>)
                _allDay.forEach((s, k) => {
                    let _type = [];

                    // console.log(notification[d][c][s])
                    let singleDay = notification[d][c][s]
                    if (singleDay && Object.keys(singleDay).length > 0) {

                        let allShiftDay = Object.keys(singleDay);
                        let singleDate;
                        allShiftDay = allShiftDay.filter(a => a !== "createdAt");
                        // console.log(allShiftDay)
                        allShiftDay.map((as, as1) => {
                            _type = JSON.parse(JSON.stringify(allType));
                            let aDay = singleDay[as];
                            // console.log(aDay)
                            let _tempStr = '';
                            aDay.forEach((o, o1) => {
                                let _temp = [];
                                let index = 0;

                                // console.log(_type)
                                _type.forEach((t, t1) => {
                                    if (t.type == o.ctrType) {
                                        _temp.push(t)
                                        index = t1;
                                    }
                                });
                                // console.log(_temp)
                                if (_temp.length > 0) {
                                    _temp[0].value = parseInt(_temp[0].value) + 1;
                                    _type[index] = _temp[0]
                                }


                                // console.log(_tempStr);
                            })
                            // console.log(_type)
                            singleDate = changeTzToNormal(singleDay.createdAt);
                            _tempStr = _tempStr
                                ?
                                _tempStr + ", " + getStr(_type)
                                : getStr(_type);

                            string.push(
                                <div
                                    onClick={() =>updateRoute(d)}
                                    className="notification-shift" style={{ paddingBottom: 17 }}
                                >
                                    {aDay[0].startTime + "-" + aDay[0].endTime}{aDay && aDay[0] && aDay[0].message ? <strong>(M)</strong> : null}: {_tempStr}
                                    {
                                        aDay && aDay[0] && showBonus(aDay[0])
                                            ?
                                            <>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className="notification-bonus-name">{showBonus(aDay[0])}</span>
                                            </>
                                            : null

                                    }
                                    {/* <div className="notification-update-date" style={{ justifyContent: 'flex-end' }}>
                                        {singleDate.date + " " + singleDate.time}
                                    </div> */}
                                </div>)
                        })
                        // console.log(singleDate)
                        string.push(
                            <div className="notification-update-date" style={{ justifyContent: 'flex-end' }}>
                                {singleDate?.date + " " + singleDate?.time}
                            </div>)
                    }

                })
            })
        })
    }
    // console.log(string)

    return string;
}

export const getStr = (str) => {
    let string = '';
    // console.log(str)
    str.forEach(s => {
        if (s.value) {
            string = string ? string + ", " + s.value + " " + s.type + " " : s.value + " " + s.type + " ";
        }
    })
    return string;
}

const showBonus = (shift) => {
    if (shift.bonusType == 2) {
        return "T/H";
    }
    else if (shift.bonusType == 3) {
        return "DP"
    }
    else if (shift.bonusType == 4) {
        return "$" + shift.bonusToCtr + "" + (shift.bonusToCtrType == 1 ? "" : "/hr")
    }
    else return ""

}

export const generateNotificationStringWithName = (notification, type, handleSingleOrder, selectedNotifications, activeDates, section, updateDate) => {
    let string = [];



    if (notification && Object.keys(notification).length > 0) {
        let _allDate = Object.keys(notification)
        // console.log(_allDate)
        _allDate.sort((b, a) => {
            return new Date(b) - new Date(a);
        })
        // console.log(_allDate, section)

        _allDate.map((d, i) => {
            // console.log(notification[d])
            //check if the date is active or inactive
            if (activeDates && activeDates.indexOf(d) >= 0) {
                string.push(<div
                    className="mt-2 notification-date"
                    onClick={() => updateDate(d, section)}
                ><strong>{dateUSStandard(d)} &nbsp; <i class="fa fa-caret-up" aria-hidden="true"></i></strong></div>)
            }
            else {

                string.push(<div
                    className="mt-2 notification-date"
                    onClick={() => updateDate(d, section)}
                ><strong>{dateUSStandard(d)} &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i></strong></div>)

                let _allClient = Object.keys(notification[d]);
                // console.log(_allClient)

                _allClient.map((c, j) => {
                    let _allDay = Object.keys(notification[d][c]);
                    // console.log(notification[d][c], _allDay)
                    string.push(<div className="notification-client">{c}</div>)
                    _allDay.map((s, k) => {
                        let _type = [...type];
                        // console.log(notification[d][c][s])
                        let singleDay = notification[d][c][s]
                        if (singleDay && singleDay.length > 0) {
                            // console.log(singleDay)
                            singleDay.map((o, o1) => {
                                let _temp = [];
                                let index = 0;

                                let singleDate = changeTzToNormal(singleDay[o1].createdAt);
                                // console.log(singleDay[o1],);
                                console.log(selectedNotifications, o.id);
                                
                                string.push(<div
                                    style={{ paddingBottom: 17 }}
                                    className={selectedNotifications && selectedNotifications.indexOf(o.id) >= 0 ? "notification-shift" : "notification-shift notification-shift-background"}
                                    onClick={() => handleSingleOrder(o, d, section)}
                                >
                                    {singleDay[o1].startTime + "-" + singleDay[o1].endTime + "(" + dayName(s) + ")"}{singleDay[o1].message ? <strong>(M)</strong> : null}:

                                    {
                                        showBonus(o)
                                            ?
                                            <>
                                                <span className="notification-bonus">{showBonus(o)}</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className="notification-bonus">{showBonus(o)}</span>
                                            </>
                                            : null

                                    }

                                    <br />
                                    <strong>
                                        {singleDay[o1].ctrType}
                                    </strong>&nbsp;&nbsp;
                                    {stringAbbreviation(singleDay[o1].contractorName)}
                                    <div className="notification-update-date">
                                        {
                                            singleDay[o1].didArriveLate && parseInt(singleDay[o1].didArriveLate) > 0
                                                ?
                                                <div className="notification-late">LATE</div>
                                                : <div className=""></div>
                                        }

                                        <div className="">
                                            {singleDate.date + " " + singleDate.time}
                                        </div>
                                    </div>
                                </div>)
                                // console.log(string);
                            })

                        }

                    })
                })
            }


        })
    }

    return string;
}

export const generateBulkOrderDetails = (data, handleUpdateBonus) => {
    console.log(data);

    
    if (data) {
        let list = Object.keys(data);
        let shift = []
        console.log(list);
        list.forEach((l) => {
            console.log(data[l]);
            shift.push(
                <>
                    <div class="mt-2 notification-date"><strong>{changeTzToNormal(data[l][0].start_date).date}</strong></div>
                    <span onClick={() => handleUpdateBonus(l, data[l][0])}>
                        <div class="notification-client">{data[l][0].client_name}</div>
                        <div class="notification-shift" style={{ paddingBottom: 17 }}>{data[l].length} {data[l][0].contractor_type_code} &nbsp;&nbsp;&nbsp;<span class="notification-bonus-name">${data[l][0].bonus_to_ctr + "" + (data[l][0].bonus_to_ctr_type == 1 ? "" : "/hr")}</span></div>
                    </span>
                    <div className="notification-update-date" style={{ justifyContent: 'flex-end' }}>
                        {changeTzToNormal(data[l][0].modified_at).date + " " + changeTzToNormal(data[l][0].modified_at).time}
                    </div>
                </>
            )
        })
        return shift;
    }

}