import React, { useState } from 'react';
import {
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
} from "reactstrap";
import classnames from "classnames";
import NotificationImportant from "../NotificationImportant";
import NotificationInformative from "../NotificationInformative";
import NotificationOther from "../NotificationOthers";
import ModalLayout from "../../../component/ModalLayout";
import BonusModal from "./bonusModal";

const allType = [
    {
        type: "CNA",
        value: 0,
    },
    {
        type: "LPN",
        value: 0,
    },
    {
        type: "RN",
        value: 0,
    },
    {
        type: "RN/LPN",
        value: 0,
    }
]

const NotificationPage = (props) => {
    const [activeTab, setActiveTab] = useState("1");
    const [bonusModal, setBonusModal] = useState(null);
    // const [type, setType] = useState(allType)

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const handleUpdateBonus = (id, data) => {
        setBonusModal({ id, data })
    }
    // console.log(allType)
    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "1"
                        })}
                        onClick={() => {
                            toggle("1");
                        }}
                    >
                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        <span className="d-none d-sm-block">Important</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "2"
                        })}
                        onClick={() => {
                            toggle("2");
                        }}
                    >
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">Informative</span>
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "3"
                        })}
                        onClick={() => {
                            toggle("3");
                        }}
                    >
                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                        <span className="d-none d-sm-block">Others</span>
                    </NavLink>
                </NavItem> */}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="pt-3">
                    <NotificationImportant
                        allData={props.allData}
                        type={[...allType]}
                        handleSingleOrder={props.handleSingleOrder}
                        selectedNotifications={props.selectedNotifications}
                        allActiveDates={props.allActiveDates}
                        handleSingleDate={props.handleSingleDate}
                        handleUpdateBonus={handleUpdateBonus}
                    />
                </TabPane>
                <TabPane tabId="2" className="pt-3">
                    <NotificationInformative
                        allData={props.allData}
                        type={[...allType]}
                        handleSingleOrder={props.handleSingleOrder}
                        selectedNotifications={props.selectedNotifications}
                        allActiveDates={props.allActiveDates}
                        handleSingleDate={props.handleSingleDate}
                        allNewContractor={props.allNewContractor}
                    />
                </TabPane>
                <TabPane tabId="3" className="pt-3">
                    <NotificationOther
                        allData={props.allData}
                        type={[...allType]}
                        handleSingleOrder={props.handleSingleOrder}
                        selectedNotifications={props.selectedNotifications}
                    />
                </TabPane>
            </TabContent>
            {
                bonusModal
                    ?
                    <ModalLayout
                        modal={bonusModal}
                        toggle={() => setBonusModal(null)}
                        title={'Update Bonus'}
                        size="xl"
                    >
                        <BonusModal
                            data={bonusModal}
                            updateBulkOrder={props.updateBulkOrder}
                            toggle={() => setBonusModal(null)}
                        />
                    </ModalLayout>

                    : null
            }

        </>
    )
}

export default NotificationPage;