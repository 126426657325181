import React, { Component } from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
} from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import NotificationFilter from './NotificationFilter';
import NotificationPage from './NotificationPage';
import { checkFilter } from "../../Utils/generalFunction";
import { GET_NOTIFICATION, UPDATE_BULK_ORDER_BONUS } from '../../APICall/urls';
import { GET_NEW_CONTRACTOR } from '../../APICall/urls/contractor';

import { postAdminAPI } from '../../APICall/index';
import { getLocalStorage } from '../../Utils/localStorage';
import { ConvertToArray } from '../../Utils/arrayFunction';
import { dateTimeFormatFull } from '../../Utils/dateFunctions';
import { notiflixLoaderStart, notiflixLoaderEnd } from '../../component/Notiflix/notiflix';
import firebase from "../../Utils/firebase";
import StaffingModal from "../StaffingSheet/modalComponent";


class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                // { title: "Lexa", link: "#" },
                // { title: "Calender", link: "#" },
                // { title: "Calender", link: "#" },
            ],
            allData: {},
            filterData: {},
            recentFilter: null,
            selectedNotifications: {
                acceptance:[],
                confirmation:[],
                acknowledge:[],
                can:[],
                co:[],
                cancel: [],
                timeSheet: [],
                signedIn: [],
            },
            allActiveDates: {
                newOrder: [],
                acceptance: [],
                confirmation: [],
                acknowledge: [],
                can: [],
                co: [],
                cancel: [],
                timeSheet: [],
                signedIn: [],
                singedOut: []
            },
            staffingModal: false,
            shifts: {},
            selectedDate: null,
            selectedClient: null,
            selectedOrder: null,
            allNewContractor:[]
        }

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Notification", this.state.breadcrumbItems);
        let filterData = {
            clients: getLocalStorage("clients") ? getLocalStorage("clients") : [],
            shiftTypes: getLocalStorage("shiftType") ? getLocalStorage("shiftType") : [],
            ctrTypes: getLocalStorage("contractorType") ? getLocalStorage("contractorType") : [],
            startDate: getLocalStorage("startDate"),
            endDate: getLocalStorage("endDate")
        }
        // console.log(filterData)
        this.setState({ filterData: filterData })

        filterData = {
            ...filterData,
            clients: ConvertToArray(getLocalStorage("clients")),
            shiftTypes: ConvertToArray(getLocalStorage("shiftType")),
            ctrTypes: ConvertToArray(getLocalStorage("contractorType")),
        }

        filterData = checkFilter(filterData)

        //selectedNotification
        let RnPlusSelectedNotification = localStorage.getItem("RnPlusSelectedNotification");
        if (RnPlusSelectedNotification) {
            RnPlusSelectedNotification = JSON.parse(RnPlusSelectedNotification);
        }
        else {
            RnPlusSelectedNotification = {
                acceptance:[],
                confirmation:[],
                acknowledge:[],
                can:[],
                co:[]
            };
        }

        this.setState({ selectedNotifications: RnPlusSelectedNotification, recentFilter: { ...filterData, isToday: true } });

        this.handleFilter({ ...filterData, isToday: true })
        this.getNewContractor()
        // this.updateDataUsingFireBase(filterData);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.basicDetails && (prevProps.basicDetails.realtimeTimeStamp != this.props.basicDetails.realtimeTimeStamp)) {
            this.handleFilter(this.state.recentFilter);
            // console.log("Sdsad");
        }
    }

    handleFilter = async (filter) => {
        // console.log(filter);
        let result = await postAdminAPI(GET_NOTIFICATION, filter)
        if (result.status) {
            // console.log(result);
            this.setState({ allData: result.data, recentFilter: filter });
        }

    }

    updateBulkOrder = async (data, callback) => {
        // console.log(data);
        notiflixLoaderStart();
        let result = await postAdminAPI(UPDATE_BULK_ORDER_BONUS, data)
        // console.log(result);
        if (result.status) {
            this.handleFilter(this.state.recentFilter);
            callback()
        }
        notiflixLoaderEnd();

    }

    handleSingleOrder = (object, date, section) => {
        console.log(object, date);

        let shifts = {
            start_time: dateTimeFormatFull(object.startDate).time + ":00",
            end_time: dateTimeFormatFull(object.endDate).time + ":00",
            id: object.shiftId
        }

        let selectedOrder = {
            id: object.id,
            ctr_type: object.ctrTypeCode,
            payable_ctr_type: object.payableCtrType,
            responses: null
        }
        // console.log(shifts)
        this.setState({ shifts: shifts, selectedDate: dateTimeFormatFull(object.startDate).date, selectedClient: object.clientId, selectedOrder: selectedOrder })

        console.log(section, object.id)
        if (section != "acceptance") {
            let notification = this.updateNotificationData(object.id, section)
            this.setState({ selectedNotifications: notification, staffingModal: parseInt(object.id) > 0 ? true : false });
            console.log(section, object.id)
        }
        else {
            console.log(section, object.id)
            this.setState({ staffingModal: parseInt(object.id) > 0 ? true : false });
        }



    }

    updateNotificationData = (id, status) => {
        let notification = localStorage.getItem("RnPlusSelectedNotification");
        notification = notification ? JSON.parse(notification) :{};
        if (notification[status]) {
            let _notif = notification[status] || [];
            const index = _notif.indexOf(id);
            if (index > -1) {
                // notification.splice(index, 1);
            }
            else {
                _notif.push(id);
            }
            notification = { ...notification, [status]: _notif }
            localStorage.setItem("RnPlusSelectedNotification", JSON.stringify(notification));

        }
        else {
            notification[status] = [];
            notification[status].push(id);
            localStorage.setItem("RnPlusSelectedNotification", JSON.stringify(notification));
        }
        console.log(notification)
        return notification
    }

    handleSingleDate = (date, section) => {
        let _allActiveDates = { ...this.state.allActiveDates };
        let index = _allActiveDates[section].indexOf(date);
        if (index >= 0) {
            _allActiveDates[section].splice(index, 1);
        }
        else {
            _allActiveDates[section].push(date);
        }
        this.setState({ allActiveDates: _allActiveDates });
    }

    updateModal = () => {
        // console.log("ASdsadsa");
        this.setState({ staffingModal: !this.state.staffingModal, selectedOrder: null })
    }

    updateNotification = () => {
        this.handleFilter(this.state.recentFilter)
    }

    updateNotificationOnApprove = (id) => {
        let notification = this.updateNotificationData(id, "acceptance")
        this.setState({ selectedNotifications: notification });
        console.log(id)
    }

    getNewContractor = async () => {
        let result = await postAdminAPI(GET_NEW_CONTRACTOR, {})
        if (result.status) {
            let _new = result.data.filter((d) => d.is_approved === "PENDING")
            this.setState({ ...this.state, allNewContractor: _new })
        }
    }


    render() {

        const { allData, selectedNotifications, allActiveDates, staffingModal, shifts, selectedDate, selectedClient, selectedOrder } = this.state;
        console.log(selectedOrder)
        return (
            <>
                <Row>
                    <Col xs="12">
                        {
                            this.state.filterData && Object.keys(this.state.filterData).length > 0
                                ?
                                <NotificationFilter
                                    handleFilter={this.handleFilter}
                                    filterData={this.state.filterData}
                                />
                                : null
                        }
                        <br />
                        {
                            <NotificationPage
                                allData={allData}
                                handleSingleOrder={this.handleSingleOrder}
                                selectedNotifications={selectedNotifications}
                                allActiveDates={allActiveDates}
                                handleSingleDate={this.handleSingleDate}
                                updateBulkOrder={this.updateBulkOrder}
                                allNewContractor={this.state.allNewContractor}

                            />
                        }

                    </Col>
                </Row>
                {
                    staffingModal && selectedOrder
                        ?
                        <StaffingModal
                            modal={staffingModal}
                            handleStaffingSheetModal={() => this.updateModal()}
                            selectedShift={shifts}
                            selectedDate={selectedDate}
                            selectedClient={selectedClient}
                            selectedOrder={selectedOrder}
                            updateAPI={this.updateNotification}
                            updateNotificationOnApprove={this.updateNotificationOnApprove}
                        />
                        : null
                }

            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        basicDetails: state.BasicDetails
    }

}

export default connect(mapStateToProps, { setBreadcrumbItems })(Notification);
